import React from "react";

import CsiroLogo from "../images/partners/csiro-logo.svg";
import MuLogo from "../images/partners/mu-logo.svg";
import ArcLogo from "../images/partners/arc-logo.svg";
import RmhLogo from "../images/partners/rmh-logo.svg";
import NswdoiLogo from "../images/partners/nswdoi-logo.svg";

function Partners() {
  return (
    <section className="bg-gray-200 text-center py-10">
      <h2 className="font-display uppercase tracking-widest text-3xl text-gray-900 max-w-full"
      >
        Partnerships
      </h2>
      <h3 className="text-xl mt-2 mb-4 font-thin max-w-4xl m-auto">
        We have partnered with some of Australia's leading research institutions for colaborations
      </h3>
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-4xl">
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={MuLogo} alt="Macquarie University Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={ArcLogo} alt="ARC Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img className="p-3" src={CsiroLogo} alt="CSIRO Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={RmhLogo} alt="RMH Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={NswdoiLogo} alt="NSW DoI Logo" />
        </div>
      </div>
    </section>
  );
}

export default Partners;
