import React from 'react';
import { Link } from 'gatsby';

function CTA() {
  return (
    <section
      id="CTA"
      className="text-gray-200 w-full bg-tertiary text-center my-0 px-8 py-16"
    >
      <h2 className="font-display uppercase tracking-widest text-3xl">
        Upcoming White Paper
      </h2>
      <p className="text-xl mt-2 mb-4 font-thin max-w-4xl m-auto">
        During August we will be releasing our White Paper covering the major
        opportunities we have uncovered in the field of Value Add From Food
        Waste Streams. If this is of interest to you <b>CLICK BELOW</b> and we'll be
        sure to send you a copy as soon as it is released.
      </p>

      <Link to="/projects/value-add-from-food-waste-streams/"
                aria-label="Read more about the upcoming white paper">
        <button className="bg-accent hover:bg-brand text-white mt-4 py-4 px-8 rounded-lg uppercase text-xl tracking-wide">
          Learn More
        </button>
      </Link>
    </section>
  );
}

export default CTA;
