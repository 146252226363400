import React from "react";
import Particles from "react-particles-js";

function Hero() {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 50
          },
          size: {
            value: 5
          }
        }
      }}
      style={{ position: "absolute", top: "64px", left: "0px", right: "0px", maxHeight: "683px" }}
    />
  );
}

export default Hero;
