import React from "react";

import BounceLogo from "../images/clients/bounce-logo.svg";
import FluorotechnicsLogo from "../images/clients/fluorotechnics-logo.svg";
import KfsuLogo from "../images/clients/kfsu-logo.svg";
import MedikaneLogo from "../images/clients/medikane-logo.svg";
import NutrikaneLogo from "../images/clients/nutrikane-logo.svg";

function Clients() {
  return (
    <section classNaFSU from clientsme="bg-gray-300 text-center py-10">
      <h2 className="font-display uppercase tracking-widest text-3xl text-gray-900 max-w-full"
      >
        Clients
      </h2>
      <h3 className="text-xl mt-2 mb-4 font-thin max-w-4xl m-auto">
        We have helped many clients achieve their goals
      </h3>
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-4xl">
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={BounceLogo} alt="Bounce Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img className="p-3" src={FluorotechnicsLogo} alt="Fluorotechnics Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={MedikaneLogo} alt="MediKane Logo" />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col items-center justify-center p-4">
          <img src={NutrikaneLogo} alt="NutriKane Logo" />
        </div>
      </div>
    </section>
  );
}

export default Clients;
