import React from 'react';
import { FaMapPin, FaEnvelope, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <section id="contact" className="text-gray-200 w-full bg-brand my-0 px-8 py-16">
      <div className="max-w-5xl mx-auto">
        <h2 className="font-display uppercase text-center tracking-widest text-3xl w-full">
          Get in Touch
        </h2>
        <div className="text-lg flex flex-wrap justify-around align-top mt-2 mb-4 font-thin max-w-4xl m-auto">
          <div>
            <p>
              <FaMapPin className="inline-block mr-4" />
              Gratuk Technologies Pty Ltd
            </p>
            <p className="pl-10">
              Level 9, Avaya House
              <br />
              123 Epping Rd
              <br />
              North Ryde, NSW 2113
            </p>
          </div>
          <div>
            <p>
              <FaPhone className="inline-block mr-4" />
              +61 2 8875 7811
            </p>
            <p>
              <FaEnvelope className="inline-block mr-4" />
              enquiries@gratuk.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
