import React from 'react';
import { Link } from 'gatsby';

import { FaLinkedin, FaTwitter, FaUserPlus } from 'react-icons/fa';
import drMalcolmBall from '../images/team/dr-malcolm-ball.png';
import rodLewis from '../images/team/rod-lewis.png';
import daveSlatyer from '../images/team/dave-slatyer.png';
import rafeDickinson from '../images/team/rafe-dickinson.png';
import nigelBall from '../images/team/nigel-ball.png';

const teamExecutives = [
  {
    name: 'Dr Malcolm Ball',
    slug: '/team/dr-malcolm-ball',
    title: 'Founder - CEO & Chief Scientist',
    linkedin: 'https://www.linkedin.com/in/malcolm-ball-a7927776/',
    twitter: null,
    photo: drMalcolmBall,
    group: 'executive'
  },
  {
    name: 'Rod Lewis',
    slug: '/team/rod-lewis',
    title: 'Founder - Commercialisation',
    linkedin: 'https://www.linkedin.com/in/rodlewismedikane/',
    twitter: null,
    photo: rodLewis,
    group: 'executive'
  }
];

const teamManagers = [
  {
    name: 'Dave Slatyer',
    slug: '/team/dave-slatyer',
    title: 'Chief Financial Officer',
    linkedin: 'https://www.linkedin.com/in/davidslatyer/',
    twitter: null,
    photo: daveSlatyer,
    group: 'executive'
  },
  {
    name: 'Rafe Dickinson',
    slug: '/team/rafe-dickinson',
    title: 'Administration Manager',
    linkedin: 'https://www.linkedin.com/in/rafe-dickinson-5406a236/',
    twitter: null,
    photo: rafeDickinson,
    group: 'management'
  }
];

const teamConsultants = [
  {
    name: 'Nigel Ball',
    slug: '/team/nigel-ball',
    title: 'Business Technology Consultant',
    linkedin: 'https://linkedin.com/nigelball135',
    twitter: 'https://twitter.com/nigelballtech',
    photo: nigelBall,
    group: 'consultants'
  }
];

function Team() {
  return (
    <section className="bg-brand text-center py-16 text-gray-200">
      <h2 id="team" className="font-display uppercase tracking-widest text-3xl max-w-full">
        Our Team
      </h2>
      <h3 className="text-xl mt-2 mb-4 font-thin max-w-3xl m-auto">
        Our team is tried and tested. A balanced group of individuals who are up
        to the challenge of finding a solution to the parts of your dreams which
        seem elusive
      </h3>
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-4xl">
        {teamExecutives.map(member => (
          <div
            key={member.name}
            className="w-full md:w-1/2 flex flex-col items-center justify-center py-8"
          >
            <img
              className="w-32 rounded-full border-4 border-gray-200 my-4"
              src={member.photo}
              alt=""
            />
            <p className="font-display text-lg p-2">{member.name}</p>
            <p className="text-sm uppercase tracking-widest pb-2">
              {member.title}
            </p>
            <p>
              { member.linkedin && 
              <a href={member.linkedin}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="Link to team member LinkedIn account">
                <FaLinkedin className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
              { member.twitter && <a href={member.twitter}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member twitter account">
                <FaTwitter className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
              <Link 
                to={member.slug}
                aria-label="Read the full team member bio">
                <FaUserPlus className="inline-block m-2 text-gray-400 text-3xl" />
              </Link>
            </p>
          </div>
        ))}
        {teamManagers.map(member => (
          <div
            key={member.name}
            className="w-full md:w-1/2 flex flex-col items-center justify-center py-4"
          >
            <img
              className="w-32 rounded-full border-4 border-gray-200 my-4"
              src={member.photo}
              alt=""
            />
            <p className="font-display text-lg p-2">{member.name}</p>
            <p className="text-sm uppercase tracking-widest pb-2">
              {member.title}
            </p>
            <p>
              { member.linkedin && 
              <a href={member.linkedin}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member LinkedIn account">
                <FaLinkedin className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
              { member.twitter && <a href={member.twitter}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member twitter account">
                <FaTwitter className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
            </p>
          </div>
        ))}
        {teamConsultants.map(member => (
          <div
            key={member.name}
            className="w-full flex flex-col items-center justify-center py-4"
          >
            <img
              className="w-32 rounded-full border-4 border-gray-200 my-4"
              src={member.photo}
              alt=""
            />
            <p className="font-display text-lg p-2">{member.name}</p>
            <p className="text-sm uppercase tracking-widest pb-2">
              {member.title}
            </p>
            <p>
              { member.linkedin && 
              <a href={member.linkedin}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member LinkedIn account">
                <FaLinkedin className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
              { member.twitter && <a href={member.twitter}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member twitter account">
                <FaTwitter className="inline-block m-2 text-gray-400 text-3xl" />
              </a>
              }
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Team;
