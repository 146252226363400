import React from "react";

import Layout from "../components/layout";
import Hero from "../components/hero";
import Partners from "../components/partners";
import Clients from "../components/clients";
import Team from "../components/team";
import SEO from "../components/seo";
import CTA from "../components/cta";
import Contact from "../components/contact";
import gratukIcon from "../images/gratuk-icon.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home Page"
        keywords={[
          `gratuk`,
          `Gratuk Technologies`,
          `project management`,
          `scientific method`
        ]}
      />

      <Hero />
      <div className="flex flex-col text-center text-base pt-20 bg-brand max-w-5xl mx-auto h-screen lg:h-auto lg:pb-20">
        <img
          src={gratukIcon}
          className="block mx-auto w-1/4"
          alt="Gratuk Technologies logo"
        />

        <h1 className="block my-8 p-2 text-3xl md:text-4xl text-gray-200 font-display font-normal bg-black rounded-lg">
          Gratuk Technologies
        </h1>

        <h2 className="block my-8 p-3 text-2xl md:text-3xl text-gray-200 font-display font-light tracking-widest">
          We Specialize In Being Generalists
        </h2>

        <p className="leading-loose font-display font-light text-gray-200 text-xl md:text-2xl uppercase tracking-widest">
          Solutions from Pattern Matching
        </p>
      </div>
      <Partners />
      <Team />
      <CTA />
      <Clients />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
